@import 'compass';
@import 'bootstrap_lib/_variables';

// ------- FONTS -------

$font-family:				  		'Ubuntu', sans-serif;
$font:						  		300 12px/22px $font-family;
$font-custom:                 		'Ubuntu', sans-serif;
$fa-font-path:        				"../fonts";
$font-icon:                   		"FontAwesome";
$flat-icon:                   		"material-design";

// ------- BORDERS ------- 

$base-border-color:					#d9d9d9;
$main_border:						1px solid $base-border-color;
$dark-border-color:					#333;
$light-border-color:				#fff;
$homepage-footer-border:            #d9d9d9;

$border-radius-base:				0;
$border-radius-small:				0;

// ------- BACKGROUNDS -------

$base-box-bg:                       #f1f1f1;
$table-background:                  $base-box-bg;
$dark-background:                   #333;
$light-background:                  #fff;
$homepage-footer-bg:                #f2f2f2;

// ------- COLORS ------- 

$base-body-color:                   #888;
$base-text-color:                   #333;
$base-heading-color:				#333;
$base-title-color:					$base-text-color;
$light-text-color:                  #fff;
$link-color:						#888;
$link-hover-color:                  #333;
$description-color:					#666;
$state-warning-text:				#f00;

$white :                            #fff;
$black :                            #222;
$red :                              #CB2C1F;

$orange :                          #FF6511;
$yellow :                          #F9C11E;


$darkPrimaryColor:   #095C66;
$primaryColor:       #4A8A79;
$lightPrimaryColor:  #90BFC8;
$textPrimaryColor:   #FFFFFF;
$accentColor:        #CB2C1F;
$primaryTextColor:   #212121;
$secondaryTextColor: #727272;
$dividerColor:       #B6B6B6;


// ------- Indents ------- 

$base-page-indent:                 	$grid-gutter-width;
$base-box-padding:                  14px 18px 13px;
$base-box-indent:                  	0 0 30px 0;

// ------- Product Elements -------

$product-name-color:                #888;
$product-name-hover-color:          #333;
$price-color:						$base-text-color;
$product-price-color:				$base-text-color;
$product-reduced-price:				#f00;
$product-special-price-color:		#333;
$product-old-price-color:			$base-body-color;



// ------- Buttons -------


$button-text-color:					#333;
$btn-font-weight:					$btn-font-weight;				//btn-default buttons weight

$btn-default-color:					$button-text-color;				//btn-default buttons color
$btn-default-bg:					#fff;				//btn-default buttons bg
$btn-default-border:				#333;			//btn-default buttons border
$btn-default-hover-color:			#fff;
$btn-default-hover-bg:				#333;
$btn-default-hover-border:			#333;

$padding-base-vertical:				11px 20px;			//used for btn-default & pagination buttons vertical indent
$padding-base-horizontal:			13px 20px;		//used for btn-default & pagination buttons horizontal indent

$padding-small-vertical:			$padding-small-vertical;		//used for btn-sm buttons vertical indent
$padding-small-horizontal:			$padding-small-horizontal;		//used for btn-sm buttons horizontal indent

$homepage-padding-btn-vertical:		$padding-base-vertical;
$homepage-padding-btn-horizontal:	$padding-base-horizontal;

		// ------- Buttons +/- ------
		
		$button-pm-color:			#c0c0c0;
		$button-pm-hover-color:		$base-text-color;
				
		
// ------- My Account List bg -------

$ma-button-bg:                      rgba(247,247,247,1);

// ------- Product Listing -------

$base-listing-border-color:					$base-border-color;
$base-listing-grid-padding:					$base-box-padding;
		
		// ------- Grid/List vars ------- 
		
		$GL-text-color:								#262626;
		$GL-title-color:							$base-text-color;
		$GL-icon-color:								#aeaeae;
		$GL-icon-color-hover:						#262626;
		
		// ------- Pagination vars -------
		
		$pagination-prevnext-color:					#777676;
		$pagination-color:							$btn-default-color;
		$pagination-hover-color:					$btn-default-hover-color;
		$pagination-bg:								$btn-default-bg;
		$pagination-border:							$btn-default-border;
		$pagination-hover-bg:						$btn-default-hover-bg;
		$pagination-active-bg:						$btn-default-hover-bg;
		$pagination-active-border:                  $btn-default-hover-border;

// ------- Product Info -------

$top-line-color:									$base-border-color;
$product-image-border:								$base-border-color;
$scroll-button-color:								#fff;
$list-info-bg:										$base-box-bg;
$scroll-button-hover-color:							$link-hover-color;
$tr-even-bg:										#fdfdfd;

// ------- Cart Steps -------

$cs-border-radius:                                  0;
$step-todo-bg:										#EDEDED;
$step-todo-border-color:							darken($step-todo-bg, 15);
$step-current-bg:									#333333;
$step-current-border-color:							darken($step-current-bg, 15);
$step-done-bg:										#6B6B6B;
$step-done-border-color:							darken($step-done-bg, 15);
$step-hover-bg:										darken($step-done-bg, 15);
$step-hover-border-color:							darken($step-hover-bg, 15);

// ------- sub heading (h2,h3) define -------

@mixin addIcon($content) {
	font-family:$font-icon;
	content:$content;	
}

@mixin addIcon2($content) {
	font-family:$flat-icon;
	content:$content;	
}

// ------- Image vars -------

@mixin product-image {
	position:relative;
	//background:$base-box-bg;
}
@mixin products-block-image {
	background:$base-box-bg;
}

@mixin blog-image {
}

@mixin post-category-image {
	@include product-image;	
	float:left;
	margin:0 20px 10px 0;
}

@mixin post-image {
	display:block;
	margin-bottom:30px;
}